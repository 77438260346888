<template>
  <div class="loan-application">
    <Container class="relative">
      <Card padding="large">
        <Margins>
          <CardHeader
            :header="$t('LOAN_APPLICATION')"
            class="loan-application__card-header"
          />
          <LoanApplicationForm class="margins__triple static" />
        </Margins>
      </Card>
    </Container>
  </div>
</template>

<script>
import { Card, CardHeader, Container, Margins } from '@/components';
import { LoanApplicationForm } from '@/containers';
import { constants } from '@/mixins';

export default {
  name: 'LoanApplication',
  mixins: [constants],
  components: {
    Container,
    Card,
    CardHeader,
    Margins,
    LoanApplicationForm,
  },
};
</script>

<style lang="scss">
.loan-application__card-header {
  position: relative;
  z-index: 1;
}
</style>
